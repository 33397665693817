<template>
  <div class="animated fadeIn">
    <b-overlay
      :show="isLoading"
      :opacity="0.5"
      spinner-variant="secondary"
      rounded="sm"
    >
      <b-container fluid class="pb-3 h-100">
        <b-row class="pb-3 h-100">
          <b-col>
            <div class="text-center h-100">
              <perfect-scrollbar :options="{ suppressScrollX: false }">
                <vue2-org-tree
                  :data="orgData"
                  :horizontal="true"
                  :collapsable="false"
                  label-class-name="bg-white"
                  :render-content="renderContent"
                  selected-class-name="bg-tomato"
                  selected-key="selectedKey"
                  @on-node-click="onNodeClick"
                />
              </perfect-scrollbar>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import Vue2OrgTree from "vue2-org-tree";
import "vue2-org-tree/dist/style.css";

Vue.use(Vue2OrgTree);

export default {
  name: "OrganizationChart",
  components: { Vue2OrgTree },
  data: function() {
    return {
      isLoading: false,
      chartData: {
        name: "<strong>JavaScript</strong> <br> 123",
        children: [
          { name: "Angular" },
          {
            name: "React",
            children: [{ name: "Preact 1", children: [{ name: "Preact2" }] }]
          },
          {
            name: "Vue",
            children: [{ name: "Moon" }]
          }
        ]
      },
      rawDataArray: [],
      orgData: {}
    };
  },
  computed: {},
  mounted() {
    this.getData();
  },
  methods: {
    findChildren(parent_id) {
      let children = [];

      for (let i = 0; i < this.rawDataArray.length; i++) {
        if (this.rawDataArray[i].reports_to === parent_id) {
          children.push(this.rawDataArray[i]);
        }
      }
      return children;
    },
    buildTree(children) {
      children.forEach(item => {
        //find who reports to root element
        item.children = this.findChildren(item.id);
        //remove used items
        this.rawDataArray = this.rawDataArray.filter(
          i => !item.children.map(c => c.id).includes(i.id)
        );

        if (!this.rawDataArray.length) return;

        this.buildTree(item.children);
      });

      return;
    },
    async getData() {
      this.isLoading = true;
      this.rawDataArray = await this.$api.get("users/orgchart/reports-to");

      this.isLoading = false;

      this.drawOrgChart();
    },
    drawOrgChart() {
      let root = { id: "0", label: "Koval Distillery" };

      this.orgData = root;

      //find who reports to root element
      root.children = this.findChildren(root.id);

      this.buildTree(root.children);
    },
    renderContent: function(h, data) {
      return data.label;
    },

    onNodeClick: function(e, data) {
      if (data.id == "0") return;

      this.$set(data, "selectedKey", !data.selectedKey);

      let routeData = this.$router.resolve({
        name: "User submission",
        params: {
          id: data.id,
          action: "view"
        }
      });
      window.open(routeData.href, "_blank");
    }
  },
  watch: {}
};
</script>

<style scoped>
::v-deep .org-tree-container {
  display: inline-block;
  padding: 15px;
  background-color: #fff;
  width: 75vw;
}

::v-deep .org-tree-node-label-inner {
  cursor: pointer;
}

::v-deep .org-tree-node-label-inner {
  border: 1px solid;
}
</style>
