var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm"
    }
  }, [_c('b-container', {
    staticClass: "pb-3 h-100",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "pb-3 h-100"
  }, [_c('b-col', [_c('div', {
    staticClass: "text-center h-100"
  }, [_c('perfect-scrollbar', {
    attrs: {
      "options": {
        suppressScrollX: false
      }
    }
  }, [_c('vue2-org-tree', {
    attrs: {
      "data": _vm.orgData,
      "horizontal": true,
      "collapsable": false,
      "label-class-name": "bg-white",
      "render-content": _vm.renderContent,
      "selected-class-name": "bg-tomato",
      "selected-key": "selectedKey"
    },
    on: {
      "on-node-click": _vm.onNodeClick
    }
  })], 1)], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }