var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "background-color": "#ffff"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm"
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    staticClass: "pl-4 mb-4",
    attrs: {
      "cols": "4",
      "lg": "4",
      "md": "3"
    }
  }, [_c('inline-switch', {
    attrs: {
      "id": "switch",
      "value": _vm.groupMode,
      "readonly": false,
      "label": "Groups mode"
    },
    on: {
      "changed": _vm.onModeChange
    }
  })], 1), _c('b-col', {
    staticClass: "p-4 text-right",
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": _vm.downloadPdf
    }
  }, [_vm._v("Download PDF")])], 1)], 1), _c('b-row', [_c('b-col', {
    ref: "imageDom",
    attrs: {
      "id": "pdf-report"
    }
  }, [_c('organization-chart', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.groupMode,
      expression: "!groupMode"
    }]
  }), _c('group-chart', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.groupMode,
      expression: "groupMode"
    }]
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }