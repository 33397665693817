<template>
  <div style="background-color: #ffff">
    <b-overlay
      :show="isLoading"
      :opacity="0.5"
      spinner-variant="secondary"
      rounded="sm"
    >
      <b-row align-h="between">
        <b-col cols="4" lg="4" md="3" class="pl-4 mb-4">
          <inline-switch
            id="switch"
            :value="groupMode"
            :readonly="false"
            label="Groups mode"
            @changed="onModeChange"
          />
        </b-col>
        <b-col cols="6" lg="3" class="p-4 text-right"
          ><b-button variant="outline-dark" @click="downloadPdf"
            >Download PDF</b-button
          ></b-col
        >
      </b-row>
      <b-row>
        <b-col ref="imageDom" id="pdf-report">
          <organization-chart v-show="!groupMode"></organization-chart>
          <group-chart v-show="groupMode"></group-chart>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import OrganizationChart from "./OrganizationChart";
import GroupChart from "./GroupChart";
import InlineSwitch from "@/components/InlineSwitch";
import jsPDF from "jspdf";
import html2canvas from "html2canvas-render-offscreen";

export default {
  components: { OrganizationChart, InlineSwitch, GroupChart },
  data: function() {
    return {
      groupMode: false,
      isLoading: false
    };
  },
  methods: {
    downloadPdf() {
      let self = this;
      this.isLoading = true;
      let htmlElement;
      let collection = document.getElementsByClassName(`org-tree-container`);

      //get 1st or 2nd element in collection if groups mode disabled/enabled
      htmlElement = collection[!this.groupMode ? 0 : 1];

      const canvasWidth = this.groupMode ? 1200 : 1200;

      const divHeight = htmlElement.clientHeight;

      html2canvas(htmlElement, {
        width: canvasWidth,
        height: divHeight,
        scrollY: 0,
        scrollX: 0
      }).then(canvas => {
        var imgData = canvas.toDataURL("image/png");

        let ratio = !this.groupMode ? 0.8 : 0.75;

        //const offsetWidthPercent = (canvas.width * ratio) / 100;
        //const offsetHeightPercent = (canvas.height * ratio) / 100;

        const pdf = new jsPDF({
          unit: "pt",
          format: [
            canvas.width * ratio, //- offsetWidthPercent * 5,
            canvas.height * ratio //- offsetHeightPercent * 5
          ]
        });
        pdf.addImage(imgData, "JPEG", 0, 0);

        pdf.save(`orgchart.pdf`);

        self.isLoading = false;
        /*
        var eleLink = document.createElement("a");
        eleLink.href = imgData; // converted image address
        eleLink.download = "orgchart";
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);
        */
      });
    },

    onModeChange() {
      this.groupMode = !this.groupMode;
    }
  }
};
</script>

<style scoped>
#pdf-report {
  -webkit-print-color-adjust: exact;
  margin-top: 0em;
  margin-left: 0em;
  margin-right: 0em;
}
</style>
